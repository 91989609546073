import { chamcha, imamdasta } from "@/assets";
import { PageTitle } from "@/components";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { VideoDetail } from "@/store";
import Slider from "../Slider";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface AnubhavIntroProps {
  videoId: string;
}

const AnubhavIntro = ({ }: AnubhavIntroProps) => {
  const youtubeVideo = useSelector(
    (state: RootState) => state.externalApis.youtubeData
  );

  const navigate = useNavigate();
  const feedbacks = useSelector((state: RootState) => state.home.dbData.feedbackList)

  const videos: VideoDetail[] = youtubeVideo?.items?.map((item: any) => ({
    title: item.snippet.title,
    description: item.snippet.description,
    videoId: item.id.videoId,
  }));
  console.log(videos);
  return (
    <section className="container mx-auto px-4 space-y-8">
      <img src={chamcha} alt="" className="absolute w-28 lg:w-40 " />
      <div className="flex flex-col items-center">
        <PageTitle title={`कुछ अनुभव हमारे लोगों के`} lineWidth={20} />
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* {videos?.map((video, videoIndex) => {
          return <YouTubeVideoPlayer videoDetail={video} key={video.videoId} />;
        })} */}
      {/* <VideoPlayerScroller /> *}

        {feedbacks.slice(0, 4).map((feedback) => (
          <div key={feedback.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={feedback.imageUrl} alt="feedback image" className="w-full h-52 object-cover" />
            <div className="p-4">
              <h3 className="font-semibold text-lg">{feedback.title}</h3>
              <p className="text-gray-600 mt-2">{feedback.description}</p>
            </div>
          </div>
        ))}
      </div> */}
      <div className="mt-4 p-8">
        {feedbacks.length > 0 && <Slider cards={feedbacks} />}
      </div>

      <div className="flex justify-center">
        <div
          className={`border-2 border-primary hover:bg-primary rounded-full cursor-pointer hover:text-white`}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/anubhav`);
          }}
        >
          <div className="flex items-center justify-center py-4 px-4 group">
            <span className="button-text mr-4">
              और अनुभव देखें
            </span>
            <span className="text-2xl relative hidden group-hover:flex">
              {/* <img src={arrowIcon} alt="" /> */}
              <HiArrowNarrowRight />
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-center lg:justify-end">
        <img src={imamdasta} alt="" className="w-20 h-20 md:w-32 md:h-32 opacity-60 lg:mr-4 object-contain" />
      </div>
    </section>
  );
};

export default AnubhavIntro;
