import { useTranslation } from "react-i18next";
import {
  groupBeans,
  leaves,
  rajivdixitjiimage,
  greenLeaves,
  GSP
} from "@/assets";
import { PageTitle } from "@/components";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { parseDynamicText } from "@/utilities/dynamicFormattedText";


const SliderArea = () => {
  const quoteList = useSelector((state: RootState) => state.home.dbData.quotes);
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- slider Area Start--> */}
      <div className="container mx-auto px-4 space-y-8">
        {/* <!-- Single Slider --> */}
        <div className="w-full flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8 md:px-16">
          <img
            src={groupBeans}
            alt="group of beans"
            className="w-16 h-16 md:w-32 md:h-32 object-contain"
          />
          <div className="text-center">
            <PageTitle title={t("home.sspTitle")} lineWidth={18} />
            <div className="font-medium text-xl lg:text-2xl mt-4">
              <span className="text-lightblack">{t("home.sspSubTitle")}</span>
            </div>
          </div>
          <img
            src={leaves}
            alt="leaves"
            className="w-16 h-16 md:w-28 md:h-28 object-contain rotate-12"
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-12 px-4 lg:px-0">
          <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center gap-8 w-full max-w-screen-xl">
            <div className="flex flex-col items-center">
              <div className="w-60 lg:w-80 flex flex-col items-center rounded-3xl border border-gray-200 shadow-lg">
                <img src={rajivdixitjiimage} alt="" className="w-[90%] mt-4" />
              </div>

              <p className="text-center mt-3 text-lg font-medium">
                राष्ट्रनायक,राष्ट्ररत्न <br />
                महान् <br />
                राजीव दीक्षित जी
                <br />
                (स्वदेशी के प्रखर वक्ता)
              </p>
            </div>
            {/* Quote Scroller */}
            <div className="w-full quotes-bg bg-contain lg:w-3/4 max-w-screen-lg h-[28rem] lg:h-[32rem] border rounded-3xl shadow-lg overflow-hidden">
              {/* <ul className="p-6 space-y-4 h-full overflow-y-auto animate-loop-scroll"> */}
              <ul className="p-6 space-y-4 h-full overflow-y-auto">
                {quoteList?.map((quote, index) => (
                  <li key={index} className="text-lg">
                    {/* Parsing the dynamic description and displaying it */}
                    {/* <p className="text-lightblack">{quote.description}</p> */}
                    {parseDynamicText(quote.description).map((entry, idx) => (
                      <div key={idx} className="space-y-4">
                        <h2 className="text-xl md:text-2xl font-bold text-gray-800 border-b border-gray-300 py-2">
                          {entry.title}
                        </h2>
                        <ul className="list-disc list-inside text-gray-700 space-y-1">
                          {entry.items.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="w-[95%] flex flex-col space-y-8 bg-blue-100 p-6 rounded-lg shadow-lg">
            <div className="flex flex-col lg:flex-row justify-center items-center space-x-8">
              <div>
                <h2 className="text-2xl font-bold text-blue-700 mb-4">वन्दे मातरम भाइयों और बहनों</h2>
                <div className="flex justify-center items-center space-x-8">
                  <div>
                    <div className="text-lg">भाई राजीव दीक्षित जी भारत को भारतीयता के मान्यता के आधार पर फिर से खड़ा करना चाहते थे, उसी काम में हम लगे हुए हैं। </div>
                    <div className="text-lg">स्वदेशीमय भारत ही भाई राजीव दीक्षित जी और हमारे जैसे उनके अनुयायिओं का अंतिम लक्ष्य है। </div>
                    <div className="text-lg">भाई राजीव दीक्षित जी के सपने स्वस्थ समृद्ध स्वदेशी स्वावलंबी स्वाभिमानी भारत के निर्माण में एक पहल आप सब भी अपने जीवन मे भाई राजीव दीक्षित जी को अवश्य सुनें</div>
                    <div className="text-lg">अपने-अपने स्तर पर सामाजिक समारोह में स्वदेशी चिकित्सा शिविर का आयोजन करवाकर जागरूकता अभियान को गतिमान बनाएं।</div>
                  </div>
                </div>
                <h2 className="text-2xl font-bold text-blue-700 mt-4">जय हिंद</h2>
              </div>
              <div className="w-44 h-44 lg:w-48 lg:h-48 rounded-full border flex justify-center items-center border-gray-200 shadow-lg">
                <img src={GSP} alt="" className="w-40 h-40 lg:w-44 lg:h-44 rounded-full text-center" />
              </div>
            </div>
            <blockquote className="text-right font-bold"> - आपका अनुज गोविन्द शरण प्रसाद</blockquote>
          </div>
          <div className="relative lg:left-[36rem]">
            <img src={greenLeaves} alt="" className="w-28 lg:w-40 mx-auto " />
          </div>
        </div>
      </div>
      {/* <!-- slider Area End--> */}
    </>
  );
};

export default SliderArea;
