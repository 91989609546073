import axios, { AxiosInstance } from "axios";

const baseURL = import.meta.env.REACT_APP_BASE_URL;
// const baseURL = "/api/";

const instance: AxiosInstance = axios.create({
  baseURL,
});

export default instance;
