import { HomeState } from "./home.type";

export const homeInitialState: HomeState = {
  dbData: {
    quotes: [],
    navigationList: [],
    feedbackList: []
  },
  localData: {
    navigationList: [],
    quotes: [],
  },
};
