import React, { useState } from "react";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import GalleryViewModal from "@/components/modals/GalleryMoal";

// Define types
export interface GalleryItem {
    category: string;
    images: string[];
}

const galleryData: GalleryItem[] = [
    {
        category: "Weddings",
        images: [
            "/images/wedding1.jpg",
            "/images/wedding2.jpg",
            "/images/wedding3.jpg",
            "/images/wedding4.jpg",
            "/images/wedding1.jpg",
            "/images/wedding2.jpg",
            "/images/wedding3.jpg",
            "/images/wedding4.jpg",
        ],
    },
    {
        category: "Corporate Events",
        images: [
            "/images/corporate1.jpg",
            "/images/corporate2.jpg",
            "/images/corporate3.jpg",
            "/images/corporate4.jpg",
        ],
    },
    {
        category: "Birthdays",
        images: [
            "/images/birthday1.jpg",
            "/images/birthday2.jpg",
            "/images/birthday3.jpg",
            "/images/birthday4.jpg",
        ],
    },
    {
        category: "Weddings",
        images: [
            "/images/wedding1.jpg",
            "/images/wedding2.jpg",
            "/images/wedding3.jpg",
            "/images/wedding4.jpg",
        ],
    },
    {
        category: "Corporate Events",
        images: [
            "/images/corporate1.jpg",
            "/images/corporate2.jpg",
            "/images/corporate3.jpg",
            "/images/corporate4.jpg",
        ],
    },
    {
        category: "Birthdays",
        images: [
            "/images/birthday1.jpg",
            "/images/birthday2.jpg",
            "/images/birthday3.jpg",
            "/images/birthday4.jpg",
        ],
    },
];

const Gallery: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<GalleryItem | null>(
        null
    );


    const openModal = (category: GalleryItem) => {
        setSelectedCategory(category);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCategory(null);
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto p-6">
            {/* Gallery Layout */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {galleryData.map((item, index) => (
                    <div
                        key={index}
                        className="relative group cursor-pointer"
                        onClick={() => openModal(item)}
                    >
                        {/* Category Card */}
                        <div className="h-64 w-full rounded-lg shadow-lg overflow-hidden bg-gray-200 group-hover:scale-105 transition-transform duration-300">
                            {/* Stack Images */}
                            <div className="relative">
                                {item.images.slice(0, 2).map((img, idx) => (
                                    <img
                                        key={idx}
                                        src={img}
                                        alt={`Thumbnail ${idx}`}
                                        className={`absolute top-${idx * 2} left-${idx * 2} w-full h-full object-cover rounded-lg`}
                                        style={{ zIndex: idx }}
                                    />
                                ))}
                            </div>
                        </div>

                        {/* Title Overlay */}
                        <div className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                            <p className="text-white font-bold text-lg">{item.category}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal */}
            {selectedCategory && (
                <GalleryViewModal isOpen={isModalOpen} onClose={closeModal} selectedCategory={selectedCategory} />
            )}
        </div>
    );
};

export default Gallery;
