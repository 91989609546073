import { herbalTreatment } from "@/assets";

interface PageTitleProps {
  title: string;
  lineWidth: number;
}

const PageTitle = ({ title, lineWidth }: PageTitleProps) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={herbalTreatment} alt="" className="w-12 h-12 md:w-16 md:h-16 object-contain" />
      <div className="font-heading text-xl md:text-2xl lg:text-4xl mt-2">
        <span className="custom-gradient ">{title}</span>
      </div>
      <div
        style={{ width: `${lineWidth}rem` }}
        className={`mt-1 h-[0.2rem] bg-gradient-to-r from-secondary/70 to-primary/95`}
      ></div>
    </div>
  );
};

export default PageTitle;
