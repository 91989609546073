import { useNavigate } from "react-router-dom";
import { HiOutlineArrowUturnLeft } from "react-icons/hi2";
import { choolhaCooking, dalchini, mint, herbalGrass, garlic } from "@/assets";
import { PageTitle } from "@/components";

const Prayas = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <!--? About Law Start--> */}
      <section className="mb-8 px-4 md:px-8">
        <div className="flex flex-col justify-center items-center">
          <div className="mt-5 w-full flex justify-between items-center">
            <img src={herbalGrass} className="w-16 h-16 md:w-20 md:h-20 object-contain" />
            <PageTitle
              title={`रसोईघर ब्रह्माण्ड की प्रथम औषधालय`}
              lineWidth={20}
            />
            <img src={garlic} className="w-16 h-16 md:w-20 md:h-20 object-contain" />
          </div>
          <div className="prayas w-full flex flex-col items-center justify-center gap-8 mt-6">
            <div className="space-y-8 lg:space-x-32 flex flex-col lg:flex-row justify-between items-center">
              <img src={choolhaCooking} alt="" className="w-3/4 md:w-1/2 object-contains" />
              <div className="md:ml-16 flex flex-col items-center lg:items-start text-center lg:text-left">
                <p className="text-lg md:text-xl leading-relaxed">
                  स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                  उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                  रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                  सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between w-full px-4 py-6 gap-4">
              <img src={dalchini} className="w-16 h-16 md:w-20 md:h-20 object-contain" />
              <div
                className={`border-2 border-primary hover:bg-primary rounded-full cursor-pointer hover:text-white`}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/AboutUs`);
                }}
              >
                <div className="flex items-center justify-center py-4 px-4 group">
                  <span className="button-text mr-4">
                    आओ लौट चलें रसोईघर की और
                  </span>
                  <span className="text-2xl relative -top-1 hidden group-hover:flex">
                    {/* <img src={arrowIcon} alt="" /> */}
                    <HiOutlineArrowUturnLeft />
                  </span>
                </div>
              </div>
              <img src={mint} className="w-16 h-16 md:w-20 md:h-20 object-contain" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About Law End--> */}
    </>
  );
};

export default Prayas;
