import { GalleryItem } from "@/pages/Gallery";
import { useState } from "react";
import Modal from "react-modal";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";

interface GalleryViewModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedCategory: GalleryItem;
}

const GalleryViewModal = ({ isOpen, onClose, selectedCategory }: GalleryViewModalProps) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Image Gallery"
            className="modal-content"
            overlayClassName="modal-overlay"
        >
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold">{selectedCategory.category}</h2>
                <button
                    className="text-gray-500 hover:text-gray-800"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>

            {/* Swiper Slider */}
            <Swiper
                spaceBetween={10}
                loop={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="main-swiper"
            >
                {selectedCategory.images.map((img, idx) => (
                    <SwiperSlide key={idx}>
                        <img
                            src={img}
                            alt={`Slide ${idx}`}
                            className="w-full h-96 object-cover rounded-lg"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Thumbs Swiper */}
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                watchSlidesProgress
                className="thumb-swiper mt-4"
            >
                {selectedCategory.images.map((img, idx) => (
                    <SwiperSlide key={idx}>
                        <img
                            src={img}
                            alt={`Thumb ${idx}`}
                            className="w-full h-24 object-cover rounded-lg cursor-pointer"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Modal>
    )
}

export default GalleryViewModal