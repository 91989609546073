import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "./routes/router";
// import { useInitialize } from "./hooks/useInitialize";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";
import { useInitialize } from "./hooks/useInitialize";
import { Toaster } from "react-hot-toast";


function App() {
  useInitialize();
  return (
    <div className="font-body">
      <BrowserRouter>
        <Toaster />
        <I18nextProvider i18n={i18n}>
          <AppRoutes />
        </I18nextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
