import { fetchAllFeedback, fetchAllQuotes} from "@/store";
import { useAppDispatch } from "@/store/store";

export const useInitialize = () => {
  const dispatch = useAppDispatch();
  dispatch(fetchAllQuotes());
  dispatch(fetchAllFeedback());
  // dispatch(fetchAllVideos());
  //   const menuList: Menu[] = useSelector(
  //     (state: RootState) => state.home.dbData.navigationList
  //   );

  // React.useEffect(() => {
  // 	const getUserInfoAndPermission = async () => {
  // 		const data = await dispatch(fetchUserInfo());
  // 		const userInfo = data.payload as UserInfo;
  // 		// if (userInfo.userId !== 0) {
  // 		// 	const permissionData = await dispatch(fetchPermissionByUser(userInfo.userId));
  // 		// 	const permission = permissionData.payload as PermissionGet[];
  // 		// 	if (permission.length === 0) {
  // 		// 		const errorState: ErropagePageState = {
  // 		// 			errorPageMessage: sourceLabele.noPermissionMessage,
  // 		// 			errorType: 'USER_DONT_HAVE_ANY_PERMISSIONS',
  // 		// 			hasError: true,
  // 		// 		};
  // 		// 		dispatch(updateError(errorState));
  // 		// 	}
  // 		// } else {
  // 		// 	dispatch(stopPermissionLoading());
  // 		// 	const errorState: ErropagePageState = {
  // 		// 		errorPageMessage: sourceLabele.noAccessMessage,
  // 		// 		errorType: 'USER_NOT_FOUND',
  // 		// 		hasError: true,
  // 		// 	};
  // 		// 	dispatch(updateError(errorState));
  // 		// }
  // 	};

  // 	getUserInfoAndPermission();
  // }, []);

  // return !user.permissionLoading && !user.userInfo.isLoading && !identificationDocument.isLoading && !lookUpTypes.isLoading;
};
