import { iconList } from "@/constant";
import { IconListTypes } from "@/constant/iconList";

interface DynamicIconProps {
  iconType: IconListTypes;
}

const DynamicIcon = ({ iconType }: DynamicIconProps) => {
  const Icon = iconList[iconType];

  if (!Icon) {
    throw new Error(`Invalid icon type "${iconType}"`);
  }
  return <Icon />;
};

export default DynamicIcon;
