import { DeepMap, FieldError } from "react-hook-form";

interface ErrorMessageProps<T> {
    errors: DeepMap<T, FieldError>;
    name: string;
}
const ErrorMessage = <T extends Record<string, any>>({ errors, name }: ErrorMessageProps<T>) => {
    const errorMessage = name.split('.').reduce<undefined | FieldError | { [x: string]: any }>((acc, key) => {
        if (acc && typeof acc === 'object' && key in acc) {
            return (acc as any)[key];
        }
        return undefined;
    }, errors);

    if (errorMessage && typeof errorMessage === 'object' && 'message' in errorMessage) {
        return (
            <p className="text-red-600 text-sm mt-1">
                {errorMessage.message}
            </p>
        );
    }

    return null;

}

export default ErrorMessage