import { Hero, Maintainence } from "@/components";

const Karndhar = () => {
  return (
    <div>
      <Hero title={"के कर्णधार"} />
      <Maintainence />
    </div>
  );
};

export default Karndhar;
