import { useNavigate } from "react-router-dom";
import { phoneIcon, mailIcon } from "@/assets";
import Button from "@/components/button";

const Footer = () => {
  const navigate = useNavigate();
  return (
    // <footer className="bg-gray-100">
    //   <div className="footer-bg z-50">
    //     {/* <!-- Footer Top--> */}
    //     <div className={`bg-auto bg-no-repeat bg-center w-full`}>
    //       {/* <div className="footer-logo p-4 w-25">
    // 				<a href="index.html">
    // 					<img src={logo} alt="" />
    // 				</a>
    // 			</div> */}
    //       <div className="flex flex-col justify-center items-center py-4">
    //         <div className="py-4 w-[90%]">
    //           <div className="flex justify-between items-center">
    //             <div className="w-72 flex justify-start">
    //               <div className="flex flex-col justify-center gap-2">
    //                 <span className="font-heading text-lg text-primary">
    //                   भाई गोविन्द शरण प्रसाद
    //                 </span>
    //                 <span className="font-medium text-md">
    //                   पता : स्वस्थ समृद्ध परिवार निर्माण प्रयास केंद्र 39
    //                   रामचंद्र एन्क्लेव श्री नारायण इंटरनेशनल स्कूल के सामने
    //                   मोहन गार्डन उत्तम नगर नई दिल्ली 110059.
    //                 </span>
    //               </div>
    //             </div>
    //             <div className="relative -left-12 font-heading">
    //               <Button
    //                 size={"large"}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   navigate("ContactUs");
    //                 }}
    //               >
    //                 संपर्क करे
    //               </Button>
    //             </div>
    //             <div className="">
    //               <div className="flex flex-col items-start">
    //                 <div className="py-2 flex items-center">
    //                   <img src={phoneIcon} alt="" className="mr-2" />{" "}
    //                   <span>+91 -9958148111</span>
    //                 </div>
    //                 <div className="py-2 flex items-center">
    //                   <img src={mailIcon} alt="" className="mr-2" />{" "}
    //                   <span>camgsp79@gmail.com</span>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="">
    //           <div className="flex flex-col items-center">
    //             <span className="font-heading text-2xl">{`विनती`}</span>
    //             <span className="font-medium">{`अपने-अपने स्तर पर सामाजिक समारोह में स्वदेशी चिकित्सा शिविर का आयोजन
    // 					करवाकर जागरूकता अभियान को गतिमान बनाएं।`}</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    <footer className="bg-gray-100 footer-bg">
      <div className="container mx-auto px-6 md:px-12 py-12">
        {/* Footer Top */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
          {/* Address */}
          <div className="space-y-2">
            <h2 className="text-lg font-heading text-primary text-center">भाई गोविन्द शरण प्रसाद</h2>
            <p className="text-sm">
              पता : स्वस्थ समृद्ध परिवार निर्माण प्रयास केंद्र 39 रामचंद्र एन्क्लेव श्री नारायण इंटरनेशनल स्कूल
              के सामने मोहन गार्डन उत्तम नगर नई दिल्ली 110059.
            </p>
          </div>

          {/* Contact Button */}
          <div className="flex justify-center">
            <Button
              size="large"
              className="bg-primary text-white px-8 py-3 rounded-2xl"
              onClick={() => navigate("ContactUs")}
            >
              संपर्क करे
            </Button>
          </div>

          {/* Contact Info */}
          <div className=" flex flex-col md:items-end ">
            <div className="py-2 flex flex-col items-center md:items-start space-y-2">
              <div className="flex items-center space-x-2">
                <img src={phoneIcon} alt="Phone Icon" className="h-5" />
                <span>+91 -9958148111</span>
              </div>
              <div className="flex items-center space-x-2">
                <img src={phoneIcon} alt="Phone Icon" className="h-5" />
                <span>+91 -8130568111</span>
              </div>
              <div className="flex items-center space-x-2">
                <img src={mailIcon} alt="Mail Icon" className="h-5" />
                <span>camgsp79@gmail.com</span>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 text-center space-y-4">
          <h2 className="font-heading text-2xl">विनती</h2>
          <p className="text-sm max-w-2xl mx-auto">
            अपने-अपने स्तर पर सामाजिक समारोह में स्वदेशी चिकित्सा शिविर का आयोजन करवाकर जागरूकता अभियान को गतिमान बनाएं।
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
