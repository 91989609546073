import { NavLink, useNavigate } from "react-router-dom";

import { Logo1 } from "@/assets";
import { NavigationBar } from "..";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <header className="bg-white shadow-sm">
      {/* <div className="container mx-auto px-6 md:px-12 flex flex-col md:flex-row items-center justify-center py-4 space-y-4 md:space-x-48 md:space-y-0">
        <div className="text-lg font-medium">फ़ोन: +91 9958148111</div>
        <ul className="flex space-x-6">
          <li className="hover:text-primary hover:scale-150 transition-transform">
            <a href="https://www.youtube.com/channel/UCy84SznSN-xnGUfX_tVks_w">
              <FaYoutube />
            </a>
          </li>
          <li className="hover:text-primary hover:scale-150 transition-transform">
            <a href="https://www.facebook.com/SWASTH.SAMRIDDH.PARIVAR/?locale=hi_IN">
              <FaFacebook />
            </a>
          </li>
          <li className="hover:text-primary hover:scale-150 transition-transform">
            <a href="https://www.instagram.com/swasthsamriddhparivar">
              <FaInstagram />
            </a>
          </li>
          <li className="hover:text-primary hover:scale-150 transition-transform">
            <a href="https://t.me/swasthsamriddhparivar">
              <FaTelegram />
            </a>
          </li>
          <li className="hover:text-primary hover:scale-150 transition-transform">
            <a href="#">
              <FaWhatsapp />
            </a>
          </li>
        </ul>

      </div> */}
      {/* <div>
              <LanguageSwitcher />
            </div> */}
      {/* <div className="w-full border-opacity-5 border-primary border-t-2 mt-3"></div> */}
      {/* Header Start */}
      <div className="border-t-2 border-primary-light">
        <div className="container mx-auto px-6 md:px-12 flex md:flex-col justify-between items-center py-6">
          {/* Logo */}
          <NavLink to={'/Home'}>
            <img src={Logo1} alt="" className="h-16" />
          </NavLink>

          <div className="flex items-center">
            {/* Main-menu  */}
            <NavigationBar />
            {/* Header-btn  */}
            {/* <button
              className="bg-primary text-white rounded-2xl px-8 py-3 font-heading"
              onClick={() => navigate("ContactUs")}
            >
              {t("home.donation")}
            </button> */}
          </div>
        </div>
        <div className="md:hidden px-4 pb-4">
          <button
            className="w-full bg-primary text-white rounded-2xl px-8 py-3 font-heading mx-auto"
            onClick={() => navigate("ContactUs")}
          >
            {t("home.donation")}
          </button>
        </div>
      </div>
      {/* Header End */}
    </header>
  );
};

export default Header;
