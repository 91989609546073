import { DividerLine } from "@/components";
import { SliderArea, Prayas, AnubhavIntro } from "./Components";


const Home = () => {
  return (
    <div className="space-y-8">
      <div className="px-4">
        <DividerLine />
      </div>
      <div>
        <SliderArea />
      </div>
      <div className="px-4">
        <DividerLine />
      </div>
      <div>
        <Prayas />
      </div>
      <div className="px-4">
        <DividerLine />
      </div>
      <div>
        <AnubhavIntro videoId="LL6SWi-yu9E" />
      </div>
    </div>
  );
};

export default Home;
