import { AxiosError, AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { redirectUrlKey, userAuthUrl } from "@/constant/appConstants";

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.code === "ERR_NETWORK") {
      localStorage.setItem(redirectUrlKey, window.location.pathname);
      window.location.href = userAuthUrl;
    }
  }
);

export default axiosInstance;
