import { createAsyncThunk } from "@reduxjs/toolkit";
import * as externalApisApi from "./externalApis.api";

export const fetchAllVideos = createAsyncThunk(
  "home/fetchAllVideos",
  async () => {
    const resp = await externalApisApi.getAllVideos();
    return resp.data;
  }
);
