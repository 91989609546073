import { VariantProps, cva } from "class-variance-authority";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const buttonStyle = cva(["transition-colors"], {
  variants: {
    variant: {
      default: ["bg-primary text-white shadow-xl", "hover:bg-primary"],
      ghost: [],
    },
    size: {
      default: ["rounded-full", "px-4", "py-3"],
      large: ["rounded-full", "px-10", "py-3", "text-xl"],
      largeIcon: [
        "rounded-full",
        "w-10",
        "h-10",
        "flex",
        "items-center",
        "justify-center",
        "p-2.5",
      ],
      smallIcon: [
        "rounded-full",
        "w-6",
        "h-6",
        "flex",
        "items-center",
        "justify-center",
        "p-0.5",
        "cursor-pointer",
      ],
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

type ButtonProps = VariantProps<typeof buttonStyle> & ComponentProps<"button">;

const Button = ({ variant, size, className, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={twMerge(buttonStyle({ variant, size }), className)}
    />
  );
};

export default Button;
