import { EMAIL_URL, FEEDBACK_URL, QUOTES_URL } from "@/constant";
import { httpClient } from "@/services/httpClient";
import {ApiResponse, FeedbackResponse, QuoteResponse} from 'ssp-contracts';
import { ContactForm } from "./home.type";

// export const getAllMenus = () => {
//   return httpClient.get<Menu[]>(MENU_URL);
// };

export const getAllQuotes = () => {
  return httpClient.get<ApiResponse<QuoteResponse[]>>(QUOTES_URL);
};

export const getAllFeedbacks = () => {
    return httpClient.get<ApiResponse<FeedbackResponse[]>>(FEEDBACK_URL);
}

export const sendEmail = (payload: ContactForm) => {
  return httpClient.post<ApiResponse<string>>(EMAIL_URL, payload);

}