import { useSelector } from "react-redux";
import { DividerLine, PageTitle } from "@/components";
import { RootState } from "@/store/store";
import { motion } from 'framer-motion';

import { useTranslation } from "react-i18next";
import { getFullUrl } from "@/utilities/commonLibFunc";
import { useState } from "react";
import { FeedbackResponse } from "ssp-contracts";
import FeedbackModal from "@/components/modals/FeedbackModal";

// const imageVariants = {
//   hidden: { opacity: 0, y: -50 },
//   visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.5 } },
// };

// const titleVariants = {
//   hidden: { opacity: 0, x: -30 },
//   visible: { opacity: 1, x: 0, transition: { delay: 0.4, duration: 0.5 } },
// };

// const descriptionVariants = {
//   hidden: { opacity: 0, x: 30 },
//   visible: { opacity: 1, x: 0, transition: { delay: 0.6, duration: 0.5 } },
// };

const Anubhav = () => {
  // const youtubeVideo = useSelector(
  //   (state: RootState) => state.externalApis.youtubeData
  // );
  const { t } = useTranslation();
  const feedbacks = useSelector((state: RootState) => state.home.dbData.feedbackList);

  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackResponse | null>(null);

  // const videos: VideoDetail[] = youtubeVideo?.items?.map((item: any) => ({
  //   title: item.snippet.title,
  //   description: item.snippet.description,
  //   videoId: item.id.videoId,
  // }));

  const handleCardClick = (feedback: FeedbackResponse) => {
    setSelectedFeedback(feedback);
  };

  const closeModal = () => {
    setSelectedFeedback(null);
  };

  return (
    <div className="container max-auto">
      {/* <Hero title={"से जुड़े लोगो का अनुभव"} /> */}
      <DividerLine />
      <div className="mx-auto px-4">
        <PageTitle title={t("anubhav.title")} lineWidth={25} />
      </div>
      <section className="py-16 px-8">
        <div className="container mx-auto px-4 flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-10">
          {feedbacks.map((item, index) => (
            <motion.div
              key={index}
              className="w-full sm:w-72 md:w-64 lg:w-80 flex flex-col rounded-lg overflow-hidden shadow-lg bg-white cursor-pointer hover:shadow-xl relative transition-transform duration-300"
              custom={index}
              initial="hidden"
              whileInView="visible"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 200, damping: 10 }}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
              }}
              viewport={{ once: true, amount: 0.2 }}
              onClick={() => handleCardClick(item)}
            >
              {/* Image */}
              <motion.img
                src={getFullUrl(item.images.preview)}
                alt={item.title}
                className="w-full h-48 object-cover rounded-t-lg"
              />

              {/* Content */}
              <motion.div className="p-4 flex flex-col h-full pb-10 relative">
                <h3 className="text-lg md:text-xl font-semibold text-center">
                  {item.title}
                </h3>

                {/* Description */}
                <p
                  className="text-sm md:text-base text-gray-600 text-center my-2 line-clamp-3"
                  title={item.description} // Tooltip for full description
                >
                  {item.description}
                </p>

                {/* View More */}
                <div className="mt-auto text-right">
                  <p
                    className="text-primary font-medium cursor-pointer hover:underline"
                    onClick={() => handleCardClick(item)}
                  >
                    {`${t("anubhav.viewMore")}...`}
                  </p>
                </div>
              </motion.div>

              {/* Bottom Gradient */}
              <div className="absolute bottom-0 left-0 right-0 h-3 bg-gradient-to-r from-secondary/70 to-primary/95"></div>
            </motion.div>
          ))}
        </div>

      </section>
      {/* Modal */}
      {selectedFeedback && (
        <FeedbackModal item={selectedFeedback} closeModal={closeModal} />
      )}
    </div>
  );
};

export default Anubhav;
