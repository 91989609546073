import { DividerLine, ErrorMessage, PageTitle } from "@/components";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  FaFacebook,
  FaYoutube,
  FaTelegram,
  FaUser, FaEnvelope, FaPhone, FaComment,
} from "react-icons/fa";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import { ContactForm, sendEnquiry } from "@/store";
import { useAppDispatch } from "@/store/store";
import createToast from "@/hooks/createToast";
import { ToastType } from "@/constant";

const ContactUs = () => {
  const dispatch = useAppDispatch();
  const methods = useForm<ContactForm>({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      message: "",
    }
  });

  const { handleSubmit, control, reset, formState: { errors } } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const formData: ContactForm = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phonenumber: data.phonenumber,
      message: data.message,
    };

    const responseData = await dispatch(sendEnquiry(formData));
    debugger;
    if (responseData.meta.requestStatus === 'fulfilled') {
      createToast(t('contact.message.successTitle'), t('contact.message.successMessage'), ToastType.Success);
      reset();
    } else {
      createToast(t('contact.message.errorTitle'), t('contact.message.errorMessage'), ToastType.Error);
    }

  });
  const { t } = useTranslation();
  return (
    <div className="container">
      {/* <Hero title={"से संपर्क करें"} /> */}
      <DividerLine />
      <div className="mx-auto px-4">
        <PageTitle title={t("contact.title")} lineWidth={10} />
      </div>
      <div className="mx-auto px-4 w-3/4 bg-white shadow-lg rounded-lg mb-10">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <form onSubmit={(e) => { e.preventDefault(); onSubmit(e); }}>
            <div className="p-8">
              <div className="flex gap-4">
                <div className="relative w-full mb-4">
                  <span className="absolute left-4 top-4 text-gray-500">
                    <FaUser />
                  </span>
                  <Controller
                    name={`firstname`}
                    control={control}
                    defaultValue=""
                    rules={{ required: t("contact.error.firstname") }}
                    render={({ field }) => (
                      <input
                        {...field}
                        placeholder={t("contact.form.firstname")}
                        className="w-full pl-10 py-3 rounded border border-gray-300 focus:border-green-500 focus:ring-green-500 transition"
                        onFocus={(e) => e.target.placeholder = ""}
                        onBlur={(e) => e.target.placeholder = t("contact.form.firstname")}
                      />
                    )}
                  />
                  <ErrorMessage errors={errors} name={`firstname`} />
                </div>
                <div className="relative w-full mb-4">
                  <span className="absolute left-4 top-4 text-gray-500">
                    <FaUser />
                  </span>
                  <Controller
                    name={`lastname`}
                    control={control}
                    defaultValue=""
                    rules={{ required: t("contact.error.lastname") }}
                    render={({ field }) => (
                      <input
                        {...field}
                        placeholder={t("contact.form.lastname")}
                        className="w-full pl-10 py-3 rounded border border-gray-300 focus:border-green-500 focus:ring-green-500 transition"
                        onFocus={(e) => e.target.placeholder = ""}
                        onBlur={(e) => e.target.placeholder = t("contact.form.lastname")}
                      />
                    )}
                  />
                  <ErrorMessage errors={errors} name={`lastname`} />
                </div>

              </div>
              <div className="relative mb-4 items-center">
                <span className="absolute left-4 top-4 text-gray-500">
                  <FaEnvelope />
                </span>
                <Controller
                  name='email'
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: t("contact.error.email"),
                    pattern: {
                      value: /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/,
                      message: t("contact.error.validEmail"),
                    }
                  }}
                  render={({ field }) => <input
                    type="text"
                    {...field}
                    placeholder={t("contact.form.email")}
                    className="w-full pl-10 rounded border py-3 border-gray-300 focus:border-green-500 focus:ring-green-500 transition"
                    onFocus={(e) => e.target.placeholder = ""}
                    onBlur={(e) => e.target.placeholder = t("contact.form.email")}
                  />}
                />
                <ErrorMessage errors={errors} name={'email'} />
              </div>
              <div className="relative mb-4">
                <span className="absolute left-4 top-4 text-gray-500">
                  <FaPhone />
                </span>
                <Controller
                  name='phonenumber'
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: t("contact.error.phone"),
                    pattern: {
                      value: /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/,
                      message: t("contact.error.validPhone"),
                    }
                  }}
                  render={({ field }) => <input
                    type="text"
                    {...field}
                    placeholder={t("contact.form.phone")}
                    className="w-full pl-10 py-3 rounded border border-gray-300 focus:border-green-500 focus:ring-green-500 transition"
                    onFocus={(e) => e.target.placeholder = ""}
                    onBlur={(e) => e.target.placeholder = t("contact.form.phone")}
                  />}
                />
                <ErrorMessage errors={errors} name={'phonenumber'} />
              </div>
              <div className="relative mb-4">
                <span className="absolute left-4 top-3 text-gray-500">
                  <FaComment />
                </span>
                <Controller
                  name='message'
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: t("contact.error.message"),
                  }}
                  render={({ field }) => <textarea
                    rows={4}
                    {...field}
                    placeholder={t("contact.form.message")}
                    className="w-full pl-10 py-3 rounded border border-gray-300 focus:border-green-500 focus:ring-green-500 transition"
                    onFocus={(e) => e.target.placeholder = ""}
                    onBlur={(e) => e.target.placeholder = t("contact.form.message")}
                  ></textarea>}
                />
                <ErrorMessage errors={errors} name={'message'} />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="w-full bg-primary text-white px-4 py-2 rounded-lg hover:bg-green-500 transition"
                >
                  {t("contact.form.submit")}
                </button>
              </div>
              {/* <div className="w-full flex justify-center mt-4 space-x-16">
                <GradientIcon href="https://www.facebook.com/SWASTH.SAMRIDDH.PARIVAR/?locale=hi_IN">
                  <FaFacebook />
                </GradientIcon>
                <GradientIcon href="https://www.youtube.com/channel/UCy84SznSN-xnGUfX_tVks_w" >
                  <FaYoutube />
                </GradientIcon>
                <GradientIcon href="https://www.instagram.com/swasthsamriddhparivar">
                  <BiLogoInstagramAlt />
                </GradientIcon>
                <GradientIcon href="https://t.me/swasthsamriddhparivar">
                  <FaTelegram className="text-3xl" />
                </GradientIcon>
                <GradientIcon href="#" >
                  <IoLogoWhatsapp />
                </GradientIcon>
              </div> */}
              <div className="w-full flex justify-center mt-4 space-x-8">
                <a href="https://www.facebook.com/SWASTH.SAMRIDDH.PARIVAR/?locale=hi_IN" className="text-gray-500">
                  <FaFacebook className="text-3xl" />
                </a>
                <a href="https://www.youtube.com/channel/UCy84SznSN-xnGUfX_tVks_w" className="text-gray-500">
                  <FaYoutube className="text-3xl" />
                </a>
                <a href="https://www.instagram.com/swasthsamriddhparivar" className="text-gray-500">
                  <BiLogoInstagramAlt className="text-3xl" />
                </a>
                <a href="https://t.me/swasthsamriddhparivar" className="text-gray-500">
                  <FaTelegram className="text-3xl" />
                </a>
                <a href="#" className="text-gray-500">
                  <IoLogoWhatsapp className="text-3xl" />
                </a>
              </div>
            </div>
          </form>
          {/* Contact Details and Map Section */}
          <div className="mt-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.060607510203!2d77.0424770755029!3d28.627945584288927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d05d2d9d7089d%3A0x179a2c3bcbaad92b!2z4KS44KWN4KS14KS44KWN4KSlIOCkuOCkruClg-CkpuCljeCkpyDgpKrgpLDgpL_gpLXgpL7gpLAg4KSo4KS_4KSw4KWN4KSu4KS-4KSjIOCkquCljeCksOCkr-CkvuCkuCDgpJXgpYfgpILgpKbgpY3gpLA!5e0!3m2!1sen!2sin!4v1729661475160!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowFullScreen
              loading="lazy"
              style={{ border: 0, borderRadius: 10 }}
              title="Location Map"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

// type GradientIconProps = {
//   href?: string;
//   children: ReactNode;
// }
// const GradientIcon = ({ href, children }: GradientIconProps) => {
//   return (
//     <a href={href} className="icon-wrapper" target="_blank" rel="noopener noreferrer">
//       {children}
//     </a>
//   )
// }

export default ContactUs;
