import { masalaWithSpoonRight, masalaWithSpoonLeft, bullet } from "@/assets";
import { DividerLine, PageTitle } from "@/components";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  const aboutDetails = [
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`,
    `स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही उद्देश्य है कि हर भाई - बहन का हो परिवार स्वस्थ और समृद्ध।
    रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।`
  ]
  return (
    <div className="container mx-auto">
      {/* <Hero title={"के बारे में"} /> */}
      <section className="">
        {/* <div className="">
          <div className="flex flex-col items-center">
            <div className="flex w-full flex-row justify-between">
              <div>
                <div>
                  <img className="masale2" src={masale2} />
                </div>
                <div>
                  <img className="masale3" src={masale3} />
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="flex flex-col items-center">
                  <div className="subTitle mt-4">
                    <h3>रसोईघर ब्रह्माण्ड की प्रथम औषधालय</h3>
                  </div>
                  <div className="content flex flex-wrap">
                    <p>
                      स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                      उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                      रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                      सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                    </p>
                    <p>
                      स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                      उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                      रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                      सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                    </p>
                    <p>
                      स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                      उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                      रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                      सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                    </p>
                    <p>
                      स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                      उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                      रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                      सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                    </p>
                    <p>
                      स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                      उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                      रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                      सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                    </p>
                    <p>
                      स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                      उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                      रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                      सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                    </p>
                    <p>
                      स्वस्थ समृद्ध परिवार एक विचार है, जिसका केवल और केवल एक ही
                      उद्देश्य है कि हर भाई-बहन का हो परिवार स्वस्थ और समृद्ध।
                      रसोईघर ब्रह्माण्ड की प्रथम औषधालय है, जो आपके परिवार को
                      सदैव स्वस्थ्यसमृद्ध बनाए रखेगा।
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="text-right pr-4">
                  <img className="masale1" src={masale1} />
                </div>
                <div>
                  <img className="masale4" src={masale4} />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <DividerLine />
        <div className="mx-auto px-4">
          <PageTitle title={t("about.title")} lineWidth={12} />
          <div className="text-center font-medium text-xl lg:text-2xl mt-4">
            <span className="text-lightblack">{t("about.subTitle")}</span>
          </div>
        </div>
      </section>
      <section className="px-4 py-8">
        <div className="flex flex-row justify-between items-center space-x-4">
          {/* Right Side - Vertical Image */}
          <div className="w-1/8">
            <img
              src={masalaWithSpoonLeft}
              alt="Masala with Spoon"
              className="w-32 md:w-52 lg:w-56 object-cover"
            />
          </div>

          {/* Left Side - Details with Bullets */}
          <div className="flex flex-col items-start w-6/8">
            <ul className="space-y-4">
              {aboutDetails.map((detail, index) => (
                <li key={index} className="flex items-start gap-2">
                  <img src={bullet} alt="Bullet" className="w-4 h-4 mt-1" />
                  <span className="text-lg leading-relaxed">{detail}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Right Side - Vertical Image */}
          <div className="w-1/8">
            <img
              src={masalaWithSpoonRight}
              alt="Masala with Spoon"
              className="w-32 md:w-52 lg:w-56 object-cover"
            />
          </div>
        </div>
      </section>

    </div>
  );
};

export default AboutUs;
