import {
  Home,
  AboutUs,
  Family,
  Karndhar,
  Member,
  Anubhav,
  Kary,
  Chikitsashivir,
  Blogs,
  ContactUs,
} from "@/pages";

import { Routes, Route, Navigate } from "react-router-dom";
import {
  ABOUT_US,
  ANUBHAV,
  BLOGS,
  CHIKITSASHIVIR,
  CONTACT_US,
  FAMILY,
  GALLERY,
  HOME,
  KARNDHAR,
  KARY,
  MEMBER,
  ROOT,
} from "./routePath";
import { WebsiteLayout } from "@/Layout";
import PageNotFound from "@/pages/PageNotFound";
import Gallery from "@/pages/Gallery";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={ROOT} element={
          <WebsiteLayout />

        }>
          {/* <Route path={ABOUT_US} element={
            <WebsiteLayout>
              <AboutUs />
            </WebsiteLayout>
          } />
          <Route path={FAMILY} element={
            <WebsiteLayout>
              <Family />
            </WebsiteLayout>
          } />
          <Route path={ANUBHAV} element={
            <WebsiteLayout>
              <Anubhav />
            </WebsiteLayout>
          } />
          <Route path={CONTACT_US} element={
            <WebsiteLayout>
              <ContactUs />
            </WebsiteLayout>
          } /> */}
          <Route path={HOME} element={<Home />} />
          <Route path={ABOUT_US} element={<AboutUs />} />
          <Route path={FAMILY} element={<Family />} />
          <Route path={KARNDHAR} element={<Karndhar />} />
          <Route path={MEMBER} element={<Member />} />
          <Route path={ANUBHAV} element={<Anubhav />} />
          <Route path={KARY} element={<Kary />} />
          <Route path={CHIKITSASHIVIR} element={<Chikitsashivir />} />
          <Route path={GALLERY} element={<Gallery />} />
          <Route path={BLOGS} element={<Blogs />} />
          <Route path={CONTACT_US} element={<ContactUs />} />
        </Route>
        <Route index element={<Navigate to={HOME} />}></Route>
        <Route path="*" element={
          <>
            <PageNotFound />
          </>
        } />
      </Routes>
    </>
  );
};

export default AppRoutes;
