import { bgMasale } from "@/assets";
import { useNavigate } from "react-router-dom";

const Maintainence = () => {
  const navigate = useNavigate();
  return (
    <section className="maintainence">
      <div className="flex justify-center items-center">
        <div className="left w-25 h-25">
          <img src={bgMasale} alt="" className="w-[20rem] h-[20rem]" />
        </div>
        <div className="right flex flex-col justify-center items-center w-[30rem] ml-10">
          <div className="title">पृष्ठ पर कार्य चल रहा है।</div>
          <div className="description">
            इस पृष्ठ पर कार्य चल रहा ह और बहुत जी जल्द आपके समक्ष आ जायेगा, तब
            तक आप दूसरे पृष्ठो से ज्ञान ले सकते है।
          </div>
          <div>
            <span
              onClick={(e) => {
                e.preventDefault();
                navigate("/Home");
              }}
              className="homeButton"
            >
              मुख्य पृष्ठ पर जाये
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Maintainence;
