import { createSlice } from "@reduxjs/toolkit";
import { externalApisInitialState } from "./externalApis.initialState";
import { fetchAllVideos } from "./externalApis.actions";

const externalApisSlice = createSlice({
  name: "externalApis",
  initialState: externalApisInitialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAllVideos.fulfilled, (state, action) => {
      state.youtubeData = action.payload;
    });
    builder.addCase(fetchAllVideos.pending, (state) => {
      state.youtubeData = [];
    });
    builder.addCase(fetchAllVideos.rejected, (state) => {
      state.youtubeData = [];
    });
  },
});

export const {} = externalApisSlice.actions;
export const { reducer: externalApisReducer } = externalApisSlice;
