import { ecofriendlyfabric } from "@/assets";

const DividerLine = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex-1 border-t-2 border-dashed border-linecolor"></div>
      <div className="">
        <img src={ecofriendlyfabric} className="p-4" />
      </div>
      <div className="flex-1 border-t-2 border-dashed border-linecolor"></div>
    </div>
  );
};

export default DividerLine;
