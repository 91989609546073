import { chikitsakranti, rajivdixit1 } from "@/assets";

interface HeroProps {
  title: string;
}

const Hero = ({ title }: HeroProps) => {
  return (
    <>
      {/* <!--? Hero Start --> */}
      <div className="">
        <div className="flex justify-between items-center">
          <div>
            <img className="w-[15rem]" src={chikitsakranti} alt="" />
          </div>
          <div className="">
            <div className="text-center">
              <h3>स्वस्थ समृद्ध परिवार</h3>
            </div>
            <div className="text-center">
              <h1>{title}</h1>
            </div>
            <div className="tagline">
              <h2>स्वस्थ रहना है समृद्ध रहना है, तो आयुर्वेद पढ़ना है!</h2>
            </div>
          </div>
          <div className="">
            <img className="w-[15rem]" src={rajivdixit1} alt="" />
          </div>
        </div>
      </div>
      {/* <!-- Hero End --> */}
    </>
  );
};

export default Hero;
