const redirectUrlKey = "CALLBACK_KEY";
const userAuthUrl = "/api/Base";

enum ToastType {
    Success = 0,
    Warning = 1,
    Error = 2,
}

export { redirectUrlKey, userAuthUrl, ToastType };
