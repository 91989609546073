import { Footer, Header } from "@/components";
import { Outlet } from "react-router-dom";

const WebsiteLayout = () => {

  return (
    <>
      <div className="min-h-screen flex flex-col overflow-hidden">
        <Header />
        <main className="flex-grow">
          <div className="container mx-auto">
            <Outlet />
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default WebsiteLayout;
