import { Menu } from "@/store";
import ListItem from "../ListItem";

interface ListMenuProps {
  menuData: Menu[];
  device: string;
  closeMenu: () => void;

}

const ListMenu = ({
  menuData,
  device,
  closeMenu,
}: ListMenuProps) => {
  return (
    <ul
      id="navigation"
      className={`flex ${device === "mobile" ? "flex-col space-y-4 p-4" : "space-x-12"} `}
      aria-hidden="true"
      role="menu"
    >
      {menuData.map((item, index) =>
        item?.isActive ? (
          <ListItem key={index} menuItemData={item} device={device} closeMenu={closeMenu} />
        ) : null
      )}
    </ul>
  );
};

export default ListMenu;
