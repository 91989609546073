
export const parseDynamicText = (text: string) => {
  const lines = text.split("\n").map((line) => line.trim());
  const result: { title: string; items: string[] }[] = [];
  let currentTitle = "";
  let currentItems: string[] = [];

  // Iterate through each line to identify titles and items
  lines.forEach((line) => {
    const isBullet = line.startsWith("*•");
    
    // Check if the line is a title (i.e., starts and ends with a `*`)
    if (line.startsWith("*") && line.endsWith("*") && !line.startsWith("*•")) {
      // If there's an existing title, push it to the result
      if (currentTitle ) {
        result.push({ title: currentTitle, items: currentItems });
      }
      // Set the new title
      currentTitle = line.replace(/\*/g, "").trim();
      currentItems = [];
    } else if (isBullet) {
      // Bullet points
      currentItems.push(line.replace("*•", "").trim());
    }
  });

  // Add the last title and items if they exist
  if (currentTitle) {
    result.push({ title: currentTitle, items: currentItems });
  }

  return result;
};