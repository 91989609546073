// Base Url
export const BASE_URL = import.meta.env.VITE_SERVER_BASE_URL;
// export const BASE_URL = 'http://localhost:3001/api';
// export const BASE_URL = 'https://ssp-server-2f2fac10678c.herokuapp.com/api';


// Home Url
const MENU_URL = `${BASE_URL}/menus`;
const QUOTES_URL = `${BASE_URL}/quotes`;
const FEEDBACK_URL = `${BASE_URL}/feedback`;
const EMAIL_URL = `${BASE_URL}/sendmail`

// External Apis Url
const youtubeUrl = import.meta.env.VITE_YOUTUBE_API_KEY;
const channelId = import.meta.env.VITE_CHANNEL_ID;
// const YOUTUBE_DATA_API_URL = `https://www.googleapis.com/youtube/v3/search?key=${youtubeUrl}&channelId=${channelId}&part=snippet,id&order=date&maxResults=10`;
const YOUTUBE_DATA_API_URL = `https://www.googleapis.com/youtube/v3`;

//SEARCH ENDPOINT
const YOUTUBE_DATA_API_URL_FOR_SEARCH = `${YOUTUBE_DATA_API_URL}/search`;
const YOUTUBE_DATA_API_URL_FOR_SEARCH_WITH_KEY_AND_CHANNELID = `${YOUTUBE_DATA_API_URL_FOR_SEARCH}?key=${youtubeUrl}&channelId=${channelId}`;
const YOUTUBE_DATA_API_URL_FOR_SEARCH_WITH_PART = `${YOUTUBE_DATA_API_URL_FOR_SEARCH_WITH_KEY_AND_CHANNELID}&part=snippet,id`;
const YOUTUBE_DATA_API_URL_FOR_SEARCH_WITH_PART_MAX_10 = `${YOUTUBE_DATA_API_URL_FOR_SEARCH_WITH_PART}&order=date&maxResults=10`;

// VIDEO ENDPOINT
const YOUTUBE_DATA_API_URL_FOR_VIDEO = `${YOUTUBE_DATA_API_URL}/videos`;
const YOUTUBE_DATA_API_URL_FOR_VIDEO_WITH_KEY_AND_CHANNELID = `${YOUTUBE_DATA_API_URL_FOR_VIDEO}?key=${youtubeUrl}&channelId=${channelId}`;
const YOUTUBE_DATA_API_URL_FOR_VIDEO_WITH_PART = `${YOUTUBE_DATA_API_URL_FOR_VIDEO_WITH_KEY_AND_CHANNELID}&part=snippet,id`;
const YOUTUBE_DATA_API_URL_FOR_VIDEO_WITH_PART_MAX_10 = `${YOUTUBE_DATA_API_URL_FOR_VIDEO_WITH_PART}&order=date&maxResults=10`;

// CHANNEL ENDPOINT
const YOUTUBE_DATA_API_URL_FOR_CHANNEL = `${YOUTUBE_DATA_API_URL}/channels`;
const YOUTUBE_DATA_API_URL_FOR_CHANNEL_WITH_KEY_AND_CHANNELID = `${YOUTUBE_DATA_API_URL_FOR_CHANNEL}?key=${youtubeUrl}&channelId=${channelId}`;
const YOUTUBE_DATA_API_URL_FOR_CHANNEL_WITH_PART = `${YOUTUBE_DATA_API_URL_FOR_CHANNEL_WITH_KEY_AND_CHANNELID}&part=snippet,id`;
const YOUTUBE_DATA_API_URL_FOR_CHANNEL_WITH_PART_MAX_10 = `${YOUTUBE_DATA_API_URL_FOR_CHANNEL_WITH_PART}&order=date&maxResults=10`;

// PLAYLIST ENDPOINT
const YOUTUBE_DATA_API_URL_FOR_PLAYLIST = `${YOUTUBE_DATA_API_URL}/playlists`;
const YOUTUBE_DATA_API_URL_FOR_PLAYLIST_WITH_KEY_AND_CHANNELID = `${YOUTUBE_DATA_API_URL_FOR_PLAYLIST}?key=${youtubeUrl}&channelId=${channelId}`;
const YOUTUBE_DATA_API_URL_FOR_PLAYLIST_WITH_PART = `${YOUTUBE_DATA_API_URL_FOR_PLAYLIST_WITH_KEY_AND_CHANNELID}&part=snippet,id`;
const YOUTUBE_DATA_API_URL_FOR_PLAYLIST_WITH_PART_MAX_10 = `${YOUTUBE_DATA_API_URL_FOR_PLAYLIST_WITH_PART}&order=date&maxResults=10`;

export {
  MENU_URL,
  QUOTES_URL,
  FEEDBACK_URL,
  EMAIL_URL,
  YOUTUBE_DATA_API_URL_FOR_CHANNEL_WITH_PART_MAX_10,
  YOUTUBE_DATA_API_URL_FOR_SEARCH_WITH_PART_MAX_10,
  YOUTUBE_DATA_API_URL_FOR_VIDEO_WITH_PART_MAX_10,
  YOUTUBE_DATA_API_URL_FOR_PLAYLIST_WITH_PART_MAX_10,
};
