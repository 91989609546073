import { createSlice } from "@reduxjs/toolkit";
import { homeInitialState } from "./home.initialState";
import { fetchAllFeedback, fetchAllQuotes } from "./home.actions";

const homeSlice = createSlice({
  name: "home",
  initialState: homeInitialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAllQuotes.fulfilled, (state, action) => {
      state.dbData.quotes = action.payload.data!;
    });
    builder.addCase(fetchAllQuotes.pending, (state) => {
      state.dbData.quotes = [];
    });
    builder.addCase(fetchAllQuotes.rejected, (state) => {
      state.dbData.quotes = [];
    });
    builder.addCase(fetchAllFeedback.fulfilled, (state, action) => {
      state.dbData.feedbackList = action.payload.data!;
    });
    builder.addCase(fetchAllFeedback.pending, (state) => {
      state.dbData.feedbackList = [];
    });
    builder.addCase(fetchAllFeedback.rejected, (state) => {
      state.dbData.feedbackList = [];
    });
  },
});

export const {} = homeSlice.actions;
export const { reducer: homeReducer } = homeSlice;
