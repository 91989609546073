import { Hero, Maintainence } from "@/components";

const Kary = () => {
  return (
    <div>
      <Hero title={"के कार्य"} />
      <Maintainence />
    </div>
  );
};

export default Kary;
