import { getFullUrl } from "@/utilities/commonLibFunc";
import { useEffect } from "react";
import { FeedbackResponse } from "ssp-contracts";

interface FeedbackModalProps {
    item: FeedbackResponse;
    closeModal: () => void;
}

const FeedbackModal = ({ item, closeModal }: FeedbackModalProps) => {

    useEffect(() => {
        // Disable scrolling on the parent
        document.body.style.overflow = "hidden";

        return () => {
            // Re-enable scrolling when the modal is closed
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            onClick={closeModal}
        >
            <div
                className="bg-white rounded-lg shadow-lg w-full max-w-5xl h-[80vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200 p-6 relative"
                onClick={(e) => e.stopPropagation()} // Prevent modal close on inner click
            >
                {/* Close Button */}
                <button
                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                    onClick={closeModal}
                >
                    ✖
                </button>

                {/* Modal Content */}
                <div className="flex flex-col items-center">
                    {/* Image */}
                    <img
                        src={getFullUrl(item.images.preview)}
                        alt={item.title}
                        className="w-full h-1/3 object-contain rounded-lg mb-4"
                    />

                    {/* Title */}
                    <h2 className="text-2xl font-bold text-center mb-2">
                        {item.title}
                    </h2>

                    {/* Description */}
                    <p className="text-gray-600 text-center mb-4">{item.description}</p>

                    {/* Additional Details */}
                    <div className="w-full mt-4">
                        <p className="text-sm text-gray-500">
                            <strong>Posted By:</strong> {`Govind Saran Prasad`}
                        </p>
                        <p className="text-sm text-gray-500">
                            <strong>Date:</strong> {new Date().toLocaleDateString()}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeedbackModal