// i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  enlocales as translationEN,
  hilocales as translationHI,
} from "@/constant";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    hi: { translation: translationHI },
  },
  lng: "hi",
  fallbackLng: "hi",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
