// import { masale1, masale2, masale3, masale4 } from '@/assets';
import FeedbackModal from "@/components/modals/FeedbackModal";
import { getFullUrl } from "@/utilities/commonLibFunc";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FeedbackResponse } from 'ssp-contracts';

// Sample card images
// const cards = [
//     { id: 1, image: masale1, title: "Herbal Care", description: "Nature's finest remedies for health." },
//     { id: 2, image: masale2, title: "Organic Foods", description: "Stay fit with fresh and organic food." },
//     { id: 3, image: masale3, title: "Wellness Retreat", description: "Relax and rejuvenate your mind and body." },
//     { id: 4, image: masale4, title: "Fitness Program", description: "Customized workouts for your goals." },
//     { id: 5, image: masale1, title: "Herbal Care", description: "Nature's finest remedies for health." },
// ];

interface SliderProps {
    cards: FeedbackResponse[];
}

const Slider = ({ cards }: SliderProps) => {
    const { t } = useTranslation();
    const [selectedFeedback, setSelectedFeedback] = useState<FeedbackResponse | null>(null);

    // const videos: VideoDetail[] = youtubeVideo?.items?.map((item: any) => ({
    //   title: item.snippet.title,
    //   description: item.snippet.description,
    //   videoId: item.id.videoId,
    // }));

    const handleCardClick = (feedback: FeedbackResponse) => {
        setSelectedFeedback(feedback);
    };

    const closeModal = () => {
        setSelectedFeedback(null);
    };


    const [activeIndex, setActiveIndex] = useState(0); // Start with the middle card
    const [numVisibleCards, setNumVisibleCards] = useState(1); // Show 5 cards on desktop

    const totalFeedbacks = cards?.length;
    useEffect(() => {
        const handleResize = () => {
            if (totalFeedbacks === 1) {
                setNumVisibleCards(1);
            } else if (totalFeedbacks === 2) {
                setNumVisibleCards(2);
            } else if (totalFeedbacks === 3 || totalFeedbacks === 4) {
                const visibleCards = window.innerWidth >= 1024 ? 3 : window.innerWidth >= 768 ? 3 : 1; // 5 cards on desktop, 3 on mobile
                setNumVisibleCards(visibleCards);
            }
            else if (totalFeedbacks >= 5) {
                const visibleCards = window.innerWidth >= 1024 ? 5 : window.innerWidth >= 768 ? 3 : 1; // 5 cards on desktop, 3 on mobile
                setNumVisibleCards(visibleCards);
            }
        };

        handleResize(); // Set initial state based on current screen size
        window.addEventListener("resize", handleResize); // Listen for window resize

        // Clean up event listener
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const nextSlide = () => {
        setActiveIndex((prev) => (prev + 1) % totalFeedbacks);
    };

    const prevSlide = () => {
        setActiveIndex((prev) => (prev - 1 + totalFeedbacks) % totalFeedbacks);
    };

    const getVisibleCards = () => {
        const visibleCards = [];
        const totalCards = totalFeedbacks;

        // Calculate visible cards
        for (let i = 0; i < numVisibleCards; i++) {
            const index = (activeIndex + i - Math.floor(numVisibleCards / 2) + totalCards) % totalCards;
            visibleCards.push(cards[index]);
        }

        return visibleCards;
    };

    // const visibleCards = cards.slice(activeIndex, activeIndex + numVisibleCards);

    return (
        <>
            <div className="relative w-full py-12 flex justify-center items-center">
                {/* Left Arrow */}
                <button
                    className="absolute -left-5 md:left-4 p-3 bg-gray-200 rounded-full shadow-md hover:bg-gray-400 z-10"
                    onClick={prevSlide}
                >
                    <FaArrowLeft className="text-xl" />
                </button>

                {/* Cards Wrapper */}
                <div className="flex justify-center items-center w-full ">
                    <AnimatePresence>
                        {getVisibleCards().map((card, index) => {
                            const centerIndex = Math.floor(numVisibleCards / 2);
                            const isCenterCard = index === centerIndex;
                            const isSideCard = index === centerIndex - 1 || index === centerIndex + 1;
                            const isFarSideCard = index === centerIndex - 2 || index === centerIndex + 2;

                            let scale = 0.7;
                            let translateX = 0;
                            let zIndex = 1;
                            let duration = 1.5; // Default duration for animations

                            if (numVisibleCards === 1) {
                                // Adjust properties for a single card
                                scale = 1.2;
                                zIndex = 10;
                                duration = 0.2; // Faster duration for a single card
                            } else if (numVisibleCards === 2) {
                                scale = isCenterCard ? 1.1 : 0.9;
                                translateX = index === 0 ? -50 : 50;
                                zIndex = isCenterCard ? 10 : 5;
                            } else if (numVisibleCards >= 3) {
                                scale = isCenterCard ? 1.2 : isSideCard ? 0.9 : 0.7;
                                translateX = isSideCard
                                    ? index < centerIndex
                                        ? 50
                                        : -50
                                    : isFarSideCard
                                        ? index < centerIndex
                                            ? 180
                                            : -180
                                        : 0;
                                zIndex = isCenterCard ? 10 : isSideCard ? 5 : 1;
                            }

                            return (
                                <motion.div
                                    key={card.id}
                                    className="flex-shrink-0"
                                    style={{
                                        transform: `scale(${scale}) translateX(${translateX}px)`,
                                        zIndex: zIndex,
                                    }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{
                                        duration: duration, // Adjust duration dynamically
                                        delay: isCenterCard ? 0 : isSideCard ? 0.2 : 0.4, // Delay for staged animations
                                        ease: "easeInOut",
                                    }}
                                >
                                    <div className="w-48 h-64 md:w-64 md:h-80 lg:w-72 lg:h-96 rounded-lg overflow-hidden shadow-lg bg-white flex flex-col mx-2 transition-transform duration-300 hover:shadow-xl hover:scale-105">
                                        {/* Image Section */}
                                        <div className="relative h-1/2">
                                            <img
                                                src={getFullUrl(card.images?.preview)}
                                                alt={card.title}
                                                className="w-full h-full object-cover rounded-t-lg"
                                            />
                                            <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-t-lg" />
                                        </div>

                                        {/* Content Section */}
                                        <div className="p-4 flex flex-col justify-between h-1/2">
                                            <h2 className="text-lg md:text-xl font-semibold text-center text-gray-800">
                                                {card.title}
                                            </h2>
                                            <p
                                                className="text-sm md:text-base text-gray-600 text-center mt-2 line-clamp-3"
                                                title={card.description}
                                            >
                                                {card.description}
                                            </p>
                                            <div className="mt-4 text-center">
                                                <p
                                                    className="text-primary font-medium text-right mt-2 absolute bottom-1 right-5 cursor-pointer"
                                                    onClick={() => handleCardClick(card)}
                                                >
                                                    {`${t("anubhav.viewMore")}...`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </AnimatePresence>


                </div>

                {/* Right Arrow */}
                <button
                    className="absolute -right-5 md:right-4 p-3 bg-gray-200 rounded-full shadow-md hover:bg-gray-400 z-10"
                    onClick={nextSlide}
                >
                    <FaArrowRight className="text-xl" />
                </button>
            </div>
            {/* Modal */}
            {selectedFeedback && (
                <FeedbackModal item={selectedFeedback} closeModal={closeModal} />
            )}
        </>
    );
};

export default Slider;
