import { DynamicIcon } from "@/components";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu } from "@/store";
import ListMenu from "../ListMenu";

interface ListItemProps {
  menuItemData: Menu;
  device: string;
  closeMenu: () => void;
}
const ListItem = ({
  menuItemData,
  device,
  closeMenu
}: ListItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    if (device !== "mobile") setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (device !== "mobile") setIsHovered(false);
  };

  const handleToggle = () => {
    if (device === "mobile") setIsOpen(!isOpen);
  };

  const handleNavigation = (url: string) => {
    navigate(url);
    closeMenu();
  }
  return (
    <li
      className={`relative font-semibold text-[16px] w-fit cursor-pointer ${device === "mobile" ? "block shadow-lg" : "inline-block"
        }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleToggle} // Toggle only for mobile
    >
      <div onClick={() => handleNavigation(menuItemData.navigationUrl)}>
        <NavLink
          to={`/${menuItemData.navigationUrl}`}
          className={({ isActive }) =>
            isActive
              ? "text-primary after:bg-primary after:absolute after:h-[2px] after:w-[120%] after:-bottom-2 after:-left-2"
              : "relative hover:text-primary after:content-[''] after:absolute after:h-[2px] after:bg-transparent after:w-[120%] after:-bottom-2 after:scale-x-2 after:transition-transform after:duration-500 hover:after:bg-primary after:-left-2"
          }
        >
          <div className="flex gap-x-2 items-center">
            <span className="text-xl">
              <DynamicIcon iconType={menuItemData.menuIcon} />
            </span>
            <span>{menuItemData.menuTitle}</span>
          </div>
        </NavLink>
      </div>

      {/* Render submenu on hover (desktop) or toggle (mobile) */}
      {menuItemData.subMenu && (isHovered || isOpen) && (
        <div
          className={`absolute left-0 mt-2 bg-white shadow-lg rounded-lg p-4 z-50 ${device === "mobile" ? "w-full" : "w-auto"
            }`}
        >
          <ListMenu menuData={menuItemData.subMenu} device={device} closeMenu={closeMenu} />
        </div>
      )}
    </li>
  );
};

export default ListItem;
