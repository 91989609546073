export const ROOT = "/";
export const HOME = "/home";
export const ABOUT_US = "/aboutUs";
export const FAMILY = "/family";
export const KARNDHAR = "/karndhar";
export const MEMBER = "/member";
export const ANUBHAV = "/anubhav";
export const GALLERY = "/gallery";
export const KARY = "/kary";
export const CHIKITSASHIVIR = "/chikitsashivir";
export const BLOGS = "/blogs";
export const CONTACT_US = "/contactUs";