import { createAsyncThunk } from "@reduxjs/toolkit";
import * as homeApi from "./home.api";
import { ApiResponse, FeedbackResponse, QuoteResponse } from "ssp-contracts";
import { AxiosError } from "axios";
import { ContactForm } from "./home.type";

export const fetchAllQuotes = createAsyncThunk<ApiResponse<QuoteResponse[]>>("home/fetchAllQuotes",async (_, thunkAPI) => {
  try {
    const resp = await homeApi.getAllQuotes();
    if (resp.data.success) {
      return resp.data;
      } else {
        return thunkAPI.rejectWithValue(resp.data.error || 'Failed to fetch quotes');
      }
    } catch(error) {
      return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch quotes');
    }
  }
);

export const fetchAllFeedback = createAsyncThunk<ApiResponse<FeedbackResponse[]>, void>('home/fetchAllFeedback', async (_, thunkAPI) => {
  try {
    const response = await homeApi.getAllFeedbacks();
    if (response.data.success) {
      return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data.error || 'Failed to fetch feedbacks');
        }
    
  } catch (error) {
    return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch feedbacks');    
  }
});

export const sendEnquiry = createAsyncThunk<ApiResponse<string>, ContactForm>('home/sendEnquiry', async (payload: ContactForm, thunkAPI) => {
  try {
    const response = await homeApi.sendEmail(payload);
    if (response.data.success) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data.error || 'Failed to send enquiry');
    }
  } catch (error) {
    return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to send enquiry');
  }
})